import { template as template_6fcac4d28fd0450f871f36162b3618f2 } from "@ember/template-compiler";
export default template_6fcac4d28fd0450f871f36162b3618f2(`
  <h2 class='pix-title-s'>Article 1. Préambule</h2>

  <p>
    Le Groupement d’intérêt public (GIP) « Pix », approuvé par arrêté du 27 avril 2017 portant approbation de la
    convention constitutive du groupement d'intérêt public « Pix », ayant son siège social 156, boulevard Haussmann
    Paris (France), a développé la Plateforme Pix dans le cadre d’un projet public en partenariat avec le Ministère en
    charge de l’Education nationale et le Ministère en charge de l’Enseignement supérieur, de la Recherche et de
    l’Innovation.
  </p>
  <p>
    La plateforme Pix est une plateforme en ligne d’évaluation et de certification des compétences numériques ayant pour
    objectif d’accompagner l’élévation du niveau général de connaissances et de compétences numériques et ainsi de
    préparer la transformation numérique de la société et de l’économie. Elle s’appuie notamment sur le cadre de
    référence européen DigComp, qui définit les compétences numériques sur huit (8) niveaux et cinq (5) grands domaines
    :
  </p>
  <ul>
    <li>Informations et données ;</li>
    <li>Communication et collaboration ;</li>
    <li>Création de contenu ;</li>
    <li>Protection et sécurité ;</li>
    <li>Environnement et numérique.</li>
  </ul>
  <p>
    Les utilisateurs inscrits sur cette plateforme peuvent passer un examen de certification de leurs compétences auprès
    d’un centre agréé par le GIP Pix.
  </p>
  <p>
    Le GIP Pix met à la disposition des centres et de leurs référents ou membres habilités (ci-après les
    «&nbsp;utilisateurs&nbsp;»), le service Pix Certif accessible en mode Saas, aux fins d’organisation des sessions
    d’examen de certification, en particulier de gestion des inscriptions et d’édition de la documentation nécessaire à
    leur mise en œuvre.
  </p>

  <h2 class='pix-title-s'>Article 2. Définitions</h2>

  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « centre » : désigne la personne morale bénéficiant de cette qualité par l’effet de la loi ou d’un règlement, ou
      d’un agrément en cours de validité au titre d’un contrat avec le GIP Pix en tant que Centre de certification Pix,
      et ayant habilité l’utilisateur à utiliser les services Pix Certif ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « certification » : désigne la certification des compétences numériques définies par l’arrêté du 27 avril 2017
      portant approbation de la convention constitutive du groupement d'intérêt public PIX, ainsi que par la
      réglementation en vigueur et à venir, passée par les candidats inscrits auprès du Centre dans le cadre d’une
      session de certification organisée par le Centre ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « conditions d’utilisation » : désigne les présentes conditions générales d’utilisation ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « données » : désigne l’ensemble des informations de toutes natures communiquées par les utilisateurs sous leur
      entière responsabilité, hébergées par le GIP Pix et destinées à être traitées dans le cadre de la mise en œuvre
      des services ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « identifiants de connexion » : identifiant et mot de passe renseignés par un utilisateur de manière personnelle
      et confidentielle lors de la connexion à son compte utilisateur, permettant l’accès aux services Pix Certif depuis
      une connexion sécurisée ;
    </li>
  </ul>
  <ul>
    <li>
      «&nbsp;Pix Certif&nbsp;»&nbsp;: désigne l’application web du GIP Pix, mise à disposition par celui-ci auprès de
      l’utilisateur en mode Saas et ayant pour objet de permettre l’inscription des candidats à la certification, ainsi
      que l’organisation et la mise en œuvre des sessions de certification&nbsp;par l’édition des documents nécessaires
      à cet effet ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      « plateforme Pix » : plateforme publique d’évaluation et de certification des compétences numériques reposant sur
      une infrastructure informatique composée d’interfaces, base(s) de données, serveur(s) et accessible en ligne en
      mode SAAS. Son code source est soumis à la licence libre GNU GPL V.3 ;
    </li>
  </ul>
  <ul>
    <li>
      «&nbsp;services&nbsp;» : accès par internet en mode Saas aux fonctionnalités de l’application Pix Certif telles
      que décrites à l’article « Présentation des services » par les utilisateurs ;
    </li>
  </ul>
  <ul>
    {{! template-lint-disable no-whitespace-within-word }}
    <li>
      «&nbsp;session&nbsp;de certification » : séance pendant laquelle le centre de certification va accueillir des
      candidats qui vont passer la certification Pix. La session de certification est définie par un lieu précis (site
      et salle), une date et un horaire.
    </li>
  </ul>
  <ul>
    <li>
      « utilisateur» : désigne un utilisateur habilité par le Centre, accédant et mettant en œuvre les fonctionnalités
      des services Pix Certif, en sa qualité de référent du Centre et/ou de membre du Centre.
    </li>
  </ul>

  <h2 class='pix-title-s'>Article 3. Objet</h2>

  <p>
    Les présentes ont pour objet de définir les conditions d’accès et d’utilisation, par les utilisateurs, de Pix Certif
    et des services.
  </p>
  <h2 class='pix-title-s'>Article 4. Entrée en vigueur - Durée</h2>
  <p>
    Les présentes conditions générales sont applicables pendant toute la durée de l’utilisation par l’utilisateur.
  </p>
  <p>
    Elles restent applicables en cas d’utilisation non-autorisée et de cessation de l’habilitation de l’utilisateur par
    son centre de rattachement.
  </p>

  <h2 class='pix-title-s'>Article 5. Acceptation et opposabilité des conditions générales</h2>

  <h3 class='pix-title-xs'>5.1 Acceptation</h3>
  <p>
    Les utilisateurs peuvent utiliser les services Pix Certif sous réserve de l’acceptation préalable des présentes
    conditions générales d’utilisation et de l’engagement qu’ils respectent les prérequis suivants&nbsp;:
  </p>
  <ul>
    <li>
      être majeur au jour de l’inscription ;
    </li>
    <li>
      être habilité par un centre&nbsp;;
    </li>
    <li>
      avoir créé un compte sur la plateforme Pix dans les conditions visées à l’article «&nbsp;Compte utilisateur&nbsp;»
      des présentes&nbsp;;
    </li>
    <li>
      disposer de la capacité juridique leur permettant de s’engager au titre des présentes conditions générales&nbsp;;
    </li>
    <li>
      disposer d’un équipement informatique adapté pour accéder à Pix Certif, sur un poste informatique adéquat, selon
      les prérequis techniques prévus aux présentes conditions d’utilisation&nbsp;;
    </li>
    <li>
      avoir transmis des informations personnelles valides au Centre en vue du rattachement de son compte utilisateur à
      l’espace Pix Certif de son centre.
    </li>
  </ul>
  <p>
    L’utilisateur, en acceptant les conditions générales d’utilisation, déclare&nbsp;:
  </p>
  <ul>
    <li>
      avoir bien pris conscience que les services sont fournis à distance ;
    </li>
    <li>
      avoir pris connaissance des conditions dans lesquelles fonctionnent les services Pix Certif ;
    </li>
    <li>
      disposer de toutes les compétences techniques nécessaires pour accéder et utiliser Pix Certif dans des conditions
      optimales ;
    </li>
    <li>
      s’être assuré du respect des prérequis techniques nécessaires&nbsp;;
    </li>
    <li>
      avoir obtenu toutes les informations nécessaires, quant aux services Pix Certif ;
    </li>
    <li>
      accepter sans réserve les présentes conditions générales d’utilisation.
    </li>
  </ul>
  <p>
    L’acceptation des présentes conditions d’utilisation est formalisée par un clic sur le bouton « J’accepte les
    conditions d’utilisation&nbsp;» par l’utilisateur qui utilise pour la première fois Pix Certif et ce, après qu’il
    ait consulté l’intégralité des présentes. Ce clic constitue la preuve que l’utilisateur a pris connaissance desdites
    stipulations et vaut acceptation des présentes.
  </p>
  <p>
    L’utilisateur dispose de la faculté de sauvegarder et d’imprimer les présentes conditions d’utilisation en utilisant
    les fonctionnalités standards de son navigateur ou de son ordinateur.
  </p>
  <p>
    L’utilisateur reconnaît que l’accès et l’utilisation des services proposés nécessitent le respect de l’ensemble des
    prescriptions définies au sein des présentes conditions d’utilisation.
  </p>

  <h3 class='pix-title-xs'>5.2 Modification</h3>
  <p>
    Les conditions générales sont susceptibles d’être modifiées ou aménagées à tout moment par le GIP Pix, notamment
    afin de faire évoluer les services.
  </p>
  <p>
    En cas de modification des conditions générales d’utilisation, les nouvelles conditions générales d’utilisation
    seront notifiées au moment de l’accès aux services par l’utilisateur et devront à nouveau être acceptées selon la
    procédure décrite à l’article « Acceptation ».
  </p>
  <p>
    Le GIP Pix se réserve le droit d’apporter aux présentes conditions d’utilisation toutes les modifications qu’il
    jugera nécessaires et utiles.
  </p>
  <p>
    Les présentes conditions d’utilisation sont opposables pendant toute la durée d’utilisation des services et jusqu’à
    ce que de nouvelles conditions d’utilisation remplacent les présentes.
  </p>
  <p>
    Tout usage du compte utilisateur par l’utilisateur après les modifications des conditions d’utilisation vaut
    acceptation par ce dernier des nouvelles conditions d’utilisation.
  </p>

  <h3 class='pix-title-xs'>5.3 Opposabilité</h3>
  <p>
    Les présentes conditions d’utilisation sont opposables à l’utilisateur dès leur acceptation par ce dernier lors de
    la création d’un compte utilisateur ou avant tout premier accès aux services.
  </p>
  <p>
    Dans tous les cas, à la date de la première utilisation des services par l’utilisateur, les conditions d’utilisation
    sont réputées lues et applicables.
  </p>
  <p>
    Les conditions d’utilisation figurant en ligne sur Pix Certif prévalent sur toute version imprimée de date
    antérieure.
  </p>
  <p>
    L’utilisateur peut à tout moment renoncer à utiliser Pix Certif et les services mais reste responsable de toute
    utilisation antérieure.
  </p>

  <h2 class='pix-title-s'>Article 6. Compte utilisateur</h2>

  <p>
    La procédure de création d’un compte utilisateur comprend les étapes suivantes.
  </p>

  <h3 class='pix-title-xs'>Etape 1.</h3>

  <p>L’utilisateur complète un formulaire internet sur la plateforme Pix.</p>
  <p>
    L’utilisateur doit indiquer une adresse électronique valide qui permettra, notamment, l’envoi d’un courrier
    électronique de confirmation de la création de son compte utilisateur. Par ailleurs, il doit choisir un mot de passe
    conforme aux spécifications suivantes :
  </p>
  <ul>
    <li>
      8 caractères minimum ;
    </li>
    <li>
      au moins une lettre ;
    </li>
    <li>
      au moins un chiffre.
    </li>
  </ul>
  <p>
    L’utilisateur doit valider, en cochant une case à cet effet, qu’il a lu et qu’il accepte les conditions
    d’utilisation de la plateforme Pix.
  </p>

  <h3 class='pix-title-xs'>Etape 2.</h3>

  <p>
    L’utilisateur demande au Centre de transmettre au GIP Pix une demande de rattachement de son compte utilisateur sur
    la plateforme Pix, au service Pix Certif.
  </p>

  <h3 class='pix-title-xs'>Etape 3.</h3>

  <p>
    Le GIP Pix effectue le rattachement du compte utilisateur de la plateforme Pix aux services Pix Certif dans un délai
    nécessaire aux vérifications de sécurité d’usage, qui permettra l’accès de l’utilisateur aux services.
  </p>

  <h3 class='pix-title-xs'>Etape 4.</h3>

  <p>
    L’utilisateur se connecte à son compte utilisateur et aux services Pix Certif aux moyens de ses identifiants de
    connexion. Ces derniers sont strictement personnels et confidentiels.
  </p>
  <p>
    L’utilisateur est seul responsable de la préservation et de la confidentialité de son mot de passe et, par
    conséquent, des conséquences d’une divulgation involontaire à quiconque
  </p>
  <p>
    Toute utilisation du compte utilisateur à partir du mot de passe attribué à l’utilisateur est présumée comme émanant
    exclusivement de l’utilisateur.
  </p>
  <p>
    L’utilisateur s’engage à notifier sans délai tous vols de son mot de passe ou toute utilisation par un tiers dont il
    aurait connaissance. Cette notification doit être adressée à&nbsp;: support@pix.fr .
  </p>
  <p>
    Il est possible de réinitialiser le mot de passe via le compte utilisateur en cliquant sur l’onglet « Mot de passe
    oublié&nbsp;?» puis en saisissant son adresse email. Un email sera adressé à l’utilisateur, lui permettant de
    définir un nouveau mot de passe qui devra lui aussi être conforme aux spécifications susvisées.
  </p>

  <h2 class='pix-title-s'>Article 7. Description des services</h2>

  <p>
    Pix Certif permet à l’utilisateur de bénéficier des services suivants :
  </p>
  <ul>
    <li>
      gestion de sessions de certification ;
    </li>
    <li>
      gestion des inscriptions ;
    </li>
    <li>
      gestion des procès-verbaux des sessions de certification ;
    </li>
    <li>
      signalement des incidents ;
    </li>
    <li>
      l’accès au kit de certification produit par le GIP Pix en vue de faciliter l’organisation des sessions et d’en
      renforcer la qualité (modèles, modes d’emploi etc.).
    </li>
  </ul>

  <h2 class='pix-title-s'>Article 8. Accès à Pix Certif</h2>

  <p>
    Les services sont normalement accessibles 24/24 heures, 7/7 jours.
  </p>
  <p>
    Le GIP Pix se réserve le droit de restreindre, totalement ou partiellement, l’accès aux services, avec un impact sur
    leur disponibilité, notamment afin d’assurer la maintenance des services dans le cadre d’interventions programmées à
    l’avance, y compris des serveurs et des infrastructures mises en œuvre pour la fourniture des services.
  </p>
  <p>
    Il appartiendra à l’utilisateur de veiller aux possibilités d’évolution des moyens informatiques et de transmission
    à sa disposition pour que ces moyens puissent s’adapter aux évolutions des services.
  </p>
  <p>
    En cas d’interruption ou d’impossibilité d’utiliser les services, l’utilisateur peut toujours s’adresser au service
    support du GIP Pix pour obtenir des informations à l’adresse suivante, en transmettant le plus précisément possible
    le problème rencontré le cas échéant sur la base de documents associés : support@pix.fr.
  </p>

  <h2 class='pix-title-s'>Article 9. Spécificités techniques</h2>

  <p>
    Le GIP Pix s’efforce de fournir un service de qualité. Il permet aux utilisateurs d'utiliser les services mis à leur
    disposition dans les meilleures conditions possibles.
  </p>
  <p>
    Le GIP Pix est donc tenu à l’égard de l’utilisateur et conformément aux normes et usages en la matière d’une
    obligation de moyens dans la mise à disposition des services.
  </p>
  <p>
    En raison de la nature et de la complexité du réseau internet, et en particulier, de ses performances techniques et
    des temps de réponse pour consulter, interroger ou transférer les données d’informations, le GIP Pix fait ses
    meilleurs efforts, conformément aux règles de l’art, pour permettre l’accès et l’utilisation des services. Le GIP
    Pix ne saurait, en effet, assurer une accessibilité ou une disponibilité absolue de la plateforme permettant l’accès
    au service.
  </p>
  <p>
    Le GIP Pix ne saurait être responsable du bon fonctionnement de l’équipement informatique de l’utilisateur ainsi que
    de son accès à internet.
  </p>
  <p>
    Le GIP Pix informe l’utilisateur des prérequis techniques suivants nécessaires au bon fonctionnement de Pix Certif
    et des services :
  </p>
  <ul>
    <li>
      un navigateur internet (Firefox, Internet Explorer 11 et au-delà, Edge, Chrome, Safari, Opera) dans une version
      récente (ne datant pas de plus de 2 ans) ;
    </li>
    <li>
      une connexion internet satisfaisante.
    </li>
  </ul>

  <h2 class='pix-title-s'>Article 10. Gestion des sessions de certification</h2>

  <p>
    Il appartient à l’utilisateur de s’assurer avec le ou les centres au(x)quel(s) il est rattaché, au minimum 2 jours
    ouvrés avant la session de certification, qu’il aura les moyens (matériel informatique et réseau) d’accéder à
    l’application Pix Certif pour réaliser l’ensemble des opérations qui lui incombent pour permettre le bon déroulé des
    sessions de certification, dans les conditions prévues à l’agrément de son centre de rattachement
  </p>

  <h2 class='pix-title-s'>Article 11. Utilisation</h2>

  <p>
    L’utilisateur s’engage à n’utiliser les services que dans les seules conditions définies aux présentes conditions
    d’utilisation et en outre :
  </p>
  <ul>
    <li>
      à ne pas détourner l’utilisation des services à des fins personnelles ou contraires à l’usage normal des services
      ;
    </li>
    <li>
      à ne commettre aucun acte de contrefaçon.
    </li>
  </ul>
  <p>
    L’utilisateur est responsable de l’utilisation de son compte utilisateur et des services. Il s’engage à les utiliser
    de façon loyale, dans le respect des présentes conditions d’utilisation, des lois et règlements applicables,
    notamment les lois relatives à la propriété intellectuelle et industrielle, à la protection des données à caractère
    personnel et à la vie privée.
  </p>

  <h2 class='pix-title-s'>Article 12. Sécurité</h2>

  <p>
    Le GIP Pix fait ses meilleurs efforts, conformément aux règles de l’art, pour sécuriser la plateforme au regard du
    risque encouru et de la nature des données traitées. Eu égard à la complexité d’Internet, l’utilisateur reconnaît et
    accepte qu’il ne saurait assurer une sécurité absolue.
  </p>
  {{! template-lint-disable no-whitespace-within-word }}
  <p>
    L’utilisateur pourra informer le GIP Pix de toute défaillance de son compte utilisateur par une notification
    adressée à : support@pix.fr.
  </p>
  <p>
    L’utilisateur accepte de prendre toutes les mesures appropriées de façon, d’une part, à sauvegarder régulièrement
    ses propres données et, d’autre part, à protéger ses données et/ou logiciels et/ou terminaux utilisés de la
    contamination par d’éventuels virus sur le réseau internet.
  </p>
  <p>
    La plateforme est un système de traitement automatisé de données.
  </p>
  <p>
    Il est interdit d'accéder ou de se maintenir, frauduleusement, dans tout ou partie de Pix Certif ou des services ou
    d’utiliser une méthode d’accès autre que l’interface mise à disposition par le GIP Pix via les services, ou de
    permettre à un tiers d’y procéder.
  </p>
  <p>
    Il est interdit d’y introduire frauduleusement des données ou même d’opérer une altération du fonctionnement des
    services.
  </p>
  <p>
    L’utilisateur veille notamment à ne pas introduire de virus, code malveillant ou toute autre technologie nuisible
    aux services.
  </p>
  <p>
    Tout accès à un espace interdit sera considéré comme un accès frauduleux au sens des dispositions du Code pénal.
  </p>
  <p>
    L’utilisateur s’engage à considérer que toutes les données dont il aura eu connaissance à l’occasion d’un tel accès
    à un espace non autorisé sont des données confidentielles et s’engage, en conséquence, à ne pas les divulguer.
  </p>
  <p>
    L’utilisateur s’interdit notamment de réaliser toute opération visant à saturer une page, les opérations de rebond
    ou toute opération ayant pour conséquence d'entraver ou de fausser le fonctionnement des services.
  </p>
  <p>
    L’utilisateur s’engage à ne pas utiliser de dispositifs ou de logiciels de toutes sortes qui auraient pour
    conséquence de perturber le bon fonctionnement des services.
  </p>
  <p>
    L’utilisateur s’engage à ne pas engager d'action qui imposerait une charge disproportionnée sur les infrastructures
    de Pix Certif.
  </p>

  <h2 class='pix-title-s'>Article 13. Maintenance et support</h2>

  <h3 class='pix-title-xs'>13.1 Support et assistance auprès de l’utilisateur</h3>
  <p>
    Le GIP Pix assure un support de nature exclusivement technique auprès de l’utilisateur qui se charge de remonter les
    éventuels dysfonctionnements des services constatés par lui-même.
  </p>
  <p>
    L’utilisateur peut joindre le support technique du GIP Pix par courrier électronique à l’adresse support@pix.fr.
  </p>
  <p>
    Le GIP Pix met à disposition des utilisateurs une FAQ permettant de répondre aux besoins les plus courants.
  </p>

  <h3 class='pix-title-xs'>13.2 Maintenance évolutive</h3>
  <p>
    Les services sont susceptibles d’évoluer dans le temps, pour tenir compte des évolutions technologiques, des besoins
    des centres et des utilisateurs.
  </p>
  <p>
    L’évolution des services peut être effectuée à tout moment par le GIP Pix.
  </p>
  <p>
    Il appartient à l’organisation et aux utilisateurs de mettre à niveau leur système d’information (moyens matériels
    et logiciels tels que la mise à jour du système d’exploitation ou navigateur) à leurs seuls frais, en cas
    d’évolution des services qui le nécessiterait de manière raisonnable, pour tenir compte des évolutions des standards
    technologiques.
  </p>
  <p>
    Le GIP Pix n’est pas responsable des dommages de toute nature qui peuvent résulter d’une indisponibilité temporaire
    de tout ou partie des services due à des opérations de maintenance, ainsi que pour toute modification, suppression
    ou interruption des services.
  </p>

  <h2 class='pix-title-s'>Article 14. Responsabilité</h2>

  <h3 class='pix-title-xs'>14.1 Responsabilité de l’utilisateur</h3>
  <p>
    L’utilisateur accède et utilise les services à ses propres risques et sous sa responsabilité et celle du centre qui
    l’a habilité, en accord avec la législation française en vigueur ainsi qu’avec les présentes conditions
    d’utilisation.
  </p>
  <p>
    L’utilisateur est exclusivement responsable de la licéité des données qu’il communique dans le cadre de l’accès et
    de l’utilisation des services et dégage le GIP Pix de toute responsabilité à cet égard.
  </p>
  <p>
    L’utilisateur est seul responsable de l'activité qui se produit par son accès aux services.
  </p>
  <p>
    L’utilisateur s’engage à ne pas perturber l’accès et/ou l’usage que pourraient faire les autres utilisateurs des
    services et à ne pas accéder aux espaces utilisateur ou comptes de tiers.
  </p>
  <p>
    Toute opération effectuée sur Pix Certif avec les identifiants de connexion de l'utilisateur est réputée faite au
    nom de ce dernier et engage sa responsabilité.
  </p>
  <p>
    L’utilisateur s’engage à ne commettre aucun acte pouvant mettre en cause la sécurité informatique du GIP Pix ou des
    autres utilisateurs, conformément à l’article « Sécurité ».
  </p>
  <p>
    L’utilisateur s’engage à ne pas interférer ou interrompre le fonctionnement normal du compte utilisateur et, plus
    généralement, des services.
  </p>
  <p>
    L’utilisateur s’engage à indemniser le GIP Pix, son directeur, ses employés et autres agents en cas de plainte,
    action, poursuite, condamnation de ces derniers résultant du non-respect des conditions d’utilisation par
    l’utilisateur.
  </p>

  <h3 class='pix-title-xs'>14.2 Responsabilité du GIP Pix</h3>
  <p>
    Le GIP Pix s’efforcera de réaliser les opérations qui lui incombent relatives au compte utilisateur et aux services,
    conformément aux règles de l’art.
  </p>
  <p>
    Le GIP Pix ne saurait être responsable de la qualité des services, ces derniers étant proposés « en l’état », ce que
    l’utilisateur accepte.
  </p>
  <p>
    Par ailleurs, le GIP Pix ne pourra voir sa responsabilité engagée :
  </p>
  <ul>
    <li>
      en cas d’indisponibilité temporaire ou totale de tout ou partie de l’accès aux services et, d’une manière
      générale, d’un défaut de performance quelconque ;
    </li>
    <li>
      du fait de l’impossibilité d’accéder aux services, liée à des destructions de matériels, aux attaques ou au
      piratage informatiques, à la privation, à la suppression ou à l'interdiction, temporaire ou définitive, et pour
      quelque cause que ce soit, de l'accès au réseau Internet ;
    </li>
    <li>
      dans les limites de la législation en vigueur, par tout dommage indirect et ce y compris notamment les pertes de
      profit, de données ou tout autre perte de biens incorporels, du fait de l’utilisation des services ou, au
      contraire, de l’impossibilité de son utilisation ;
    </li>
    <li>
      du fait d'un dysfonctionnement, d'une indisponibilité d'accès, d'une mauvaise utilisation, d'une mauvaise
      configuration de l'ordinateur ou appareils nomades (tablettes et mobiles) de l’utilisateur, ou encore de l'emploi
      d'un navigateur ou système d’exploitation peu usité par l’utilisateur ou non conforme aux prérequis techniques ;
    </li>
    <li>
      en cas d’usage frauduleux ou abusif ou dû à une divulgation volontaire ou involontaire à quiconque des codes
      d’accès confiés à l’utilisateur&nbsp;;
    </li>
    <li>
      du fait d’un retrait des habilitations qui lui sont conférées à la demande d’un centre auquel il est rattaché et
      ayant donné lieu à une coupure de l’accès aux services.
    </li>
  </ul>
  <p>
    D’un commun accord, les parties conviennent que l’utilisateur ne peut pas lui-même former une quelconque action en
    responsabilité et renonce à recours contre le GIP Pix, dans la mesure où le préjudice subi ne peut être subi,
    exclusivement, que par le Centre, et, en cas de préjudice propres, seul le Centre peut agir en son nom pour demander
    réparation de son préjudice, étant précisé que :
  </p>
  <ul>
    <li>
      la responsabilité du GIP Pix pourra être engagée, dans les conditions de droit commun, à raison des dommages
      directs et prévisibles subis par l’utilisateur, à l’exclusion de tous dommages indirects ;
    </li>
    <li>
      sont considérés comme dommages indirects notamment les pertes de données, de temps, de bénéfices, de chiffre
      d’affaires, de marges, pertes de commandes, de clients, d’exploitation, de revenus, d’actions commerciales ou
      encore l’atteinte à l’image de marque, les résultats escomptés et l’action de tiers, et ce même si le GIP Pix
      était dûment informé du risque de survenance de tels dommages ;
    </li>
    <li>
      la responsabilité du GIP Pix est, d’un commun accord avec l’utilisateur, et tous faits générateurs confondus,
      limitée au montant hors taxe du prix payé par le centre en contrepartie des services au cours desquels le fait à
      l’origine du dommage a été commis ;
    </li>
    <li>
      la responsabilité du GIP Pix ne peut donc, d’un commun accord, être mise en jeu à raison des services, tant que
      les services sont fournis à titre gratuit au centre et à l’utilisateur.
    </li>
  </ul>
  <p>
    La présente clause reste applicable en cas de nullité, de résolution, de résiliation ou d’anéantissement des
    présentes relations contractuelles.
  </p>

  <h2 class='pix-title-s'>Article 15. Propriété intellectuelle</h2>

  <p>
    Le GIP Pix est, et reste, propriétaire exclusif des éléments protégés des services selon les termes du Code de la
    propriété intellectuelle, à l’exclusion de toutes éventuelles ressources qui relèvent, le cas échéant, de la
    propriété intellectuelle de tiers.
  </p>
  <p>
    Le droit de propriété du GIP Pix s’étend en particulier et dans ce cadre, à toute la documentation, les bases de
    données de Pix Certif, des services, ainsi que leurs interfaces et la documentation associée telle que définie par
    le Code de la propriété intellectuelle, sans que cette liste ne soit exhaustive.
  </p>
  <p>
    Les présentes conditions d’utilisation ne sauraient avoir pour effet de transférer ou de céder les droits de
    propriété intellectuelle des plateformes Pix ou services, aux utilisateurs.
  </p>
  <p>
    En conséquence, les utilisateurs s'interdisent tout agissement et tout acte susceptible de porter atteinte
    directement ou non aux droits de propriété intellectuelle du GIP Pix ou des tiers. Notamment, ils s’interdisent de
    modifier, copier, reproduire, télécharger, diffuser, transmettre, exploiter commercialement et/ou distribuer de
    quelque façon que ce soit les services ou les pages des plateformes Pix et Pix Certif, dans la mesure où ils
    feraient respectivement l’objet d’une protection au titre d’un droit de propriété intellectuelle pour leurs parties
    non soumises à une licence Open Source.
  </p>
  <p>
    Seule une utilisation des services conformément à leur destination est autorisée. Toute utilisation non conforme ou
    non expressément autorisée par le GIP Pix au titre des présentes conditions d’utilisation est illicite.
  </p>

  <h2 class='pix-title-s'>Article 16. Liens hypertextes</h2>

  <p>
    Le GIP Pix se réserve la possibilité de mettre en place des hyperliens sur Pix Certif donnant accès à des pages web
    autres que celles des plateformes Pix et Pix Certif.
  </p>
  <p>
    Le GIP Pix décline toute responsabilité quant au contenu des informations fournies sur ces sites au titre de
    l’activation des hyperliens.
  </p>
  <p>
    Chaque utilisateur accède aux sites tiers sous sa seule et entière responsabilité.
  </p>
  <p>
    Toute mise en place d’un lien hypertexte sur un site tiers redirigeant vers l’une quelconque des pages internet de
    la plateforme Pix requiert l’autorisation préalable et écrite du GIP Pix.
  </p>

  <h2 class='pix-title-s'>Article 17. Données à caractère personnel</h2>

  <h3 class='pix-title-xs'>17.1 Information de l’utilisation</h3>
  <p>
    Dans le cadre de ses rapports avec les utilisateurs, le GIP Pix, en qualité de responsable de traitement, peut être
    amené à traiter les données à caractère personnel suivantes relatives à l’identité d’un utilisateur, à savoir :
    nom(s) et prénom(s), fonction, qualification, adresse email professionnelle, numéro de téléphone, entité ou centre
    de rattachement, expériences et CV.
  </p>
  <p>
    Ces données peuvent lui être transmises par&nbsp;:
  </p>
  <ul>
    <li>
      l’utilisateur lors de son inscription sur la plateforme Pix&nbsp;;
    </li>
    <li>
      le centre, en vue&nbsp;:
      <ul>
        <li>
          de la gestion de l’accès aux services ;
        </li>
        <li>
          la gestion et le suivi du compte utilisateur Pix Certif (création, modification, suppression) ;
        </li>
        <li>
          la gestion des demandes de droit d’accès, de rectification et d’opposition au traitement des données
          personnelles des utilisateurs concernés.
        </li>
      </ul>
    </li>
  </ul>
  <p>
    L’utilisateur est informé et accepte que ses données soient traitées par le GIP Pix pour la finalité précitée.
  </p>
  <p>
    Ce traitement est effectué sur les bases légales suivantes : votre consentement (lorsque vous acceptez les présentes
    conditions), l'exécution des présentes conditions générales, et l'intérêt légitime du GIP Pix à mettre en œuvre des
    traitements de gestion.
  </p>
  <p>
    Ces données sont destinées au GIP Pix responsable de traitement, à son personnel habilité, et à ses éventuels
    sous-traitants.
  </p>
  <p>
    Les données seront conservées pendant une durée de 5 ans maximum à compter de la dernière utilisation ou du dernier
    accès au compte utilisateur et/ou à Pix Certif, et archivées selon les délais de prescription légale (5 ans)
  </p>
  <p>
    Toute personne concernée par le traitement dispose d’un droit d’interrogation, d’accès, de rectification,
    d’effacement des données, d’un droit à la limitation du traitement et à la portabilité des données ; ainsi que d’un
    droit d’opposition pour motif légitime.
  </p>
  <p>
    Par ailleurs, l’utilisateur dispose d’un droit de formuler des directives spécifiques et générales concernant la
    conservation, l’effacement et la communication de ses données post-mortem. En ce qui concerne les directives
    générales, elles pourront être adressées à un tiers qui sera désigné par Décret.
  </p>
  <p>
    La communication de directives spécifiques post-mortem et l’exercice des droits s’effectuent par courrier
    électronique à l’adresse email suivante : dpo@pix.fr. La photocopie d’un titre d’identité signé et des informations
    complémentaires pourront être exigées en cas de doute sur l’identité de l’utilisateur.
  </p>
  <p>
    Enfin, l’utilisateur a le droit d’introduire une réclamation auprès d’une autorité de contrôle et notamment de la
    Commission nationale Informatique et libertés (ci-après « CNIL ») et de retirer son consentement à tout moment.
  </p>

  <h3 class='pix-title-xs'>17.2 Sous-traitance</h3>
  <p>
    L’utilisateur est susceptible de traiter les données à caractère personnel des candidats en qualité de sous-traitant
    du centre (ou de sous-traitant de second rang du GIP Pix), le centre étant lui-même sous-traitant du GIP Pix.
  </p>
  <p>
    Le terme de sous-traitant au sens de la réglementation informatique et libertés et en particulier du Règlement UE
    2016/679 et de la présente clause, s’entend de la personne physique (en l’occurrence l’utilisateur en tant que
    sous-traitant de second rang) ou morale (le centre en tant que sous-traitant de premier rang) qui traite des données
    à caractère personnel pour le compte du responsable de traitement, le GIP Pix.
  </p>
  <p>
    Il appartient à l’utilisateur de respecter l’accord contractuel prévu entre lui et le centre conformément à
    l’article 28 du Règlement UE 2016/679, c’est à dire les obligations au moins équivalentes en protection à celles
    imposées au centre, telles que figurant à la convention d’agrément entre le centre et le GIP Pix.
  </p>
  <p>
    L’utilisateur peut demander communication de cette convention auprès du centre.
  </p>

  <h2 class='pix-title-s'>18. Résolution et résiliation</h2>

  <h3 class='pix-title-xs'>18.1 Désinscription</h3>
  <p>
    L’accès aux services par l’utilisateur est effectif et valide tant que l’utilisateur&nbsp;:
  </p>
  <ul>
    <li>
      utilise les services&nbsp;; et,
    </li>
    <li>
      est habilité par le centre auquel il est rattaché à utiliser les services.
    </li>
  </ul>
  <p>
    En cas de retrait de son habilitation par le centre auquel il est rattaché, l’utilisateur s’engage à s’abstenir de
    tout accès et de toute utilisation des services.
  </p>

  <h3 class='pix-title-xs'>18.2 Suspension et exclusion</h3>
  <p>
    En cas de manquement aux obligations des présentes conditions d’utilisation par l’utilisateur, le GIP Pix se réserve
    le droit, sans indemnité ni remboursement, huit (8) jours après l’envoi à l’utilisateur d’un courrier électronique
    lui demandant de se conformer aux présentes conditions d’utilisation, de suspendre l’accès aux services jusqu’à ce
    que la cause de la suspension ait disparu.
  </p>
  <p>
    En cas de manquement répété aux obligations des présentes conditions par l’utilisateur, le GIP Pix se réserve le
    droit, sans indemnité ni remboursement, huit (8) jours après l’envoi à l’utilisateur d’un courrier électronique lui
    demandant de se conformer aux présentes conditions d’utilisation resté infructueux, de résilier l’inscription de
    l’utilisateur et de mettre fin à l’accès à son compte utilisateur ou d’interdire l’accès de tout ou partie des
    services, sans préjudice de toute action de droit commun qui pourrait lui être ouverte.
  </p>

  <h2 class='pix-title-s'>19. Convention de preuve</h2>

  <p>
    L’acceptation en ligne des présentes conditions d’utilisation par voie électronique a entre les parties la même
    valeur probante que l’accord sur support papier.
  </p>
  <p>
    Les registres informatisés conservés dans les systèmes informatiques du GIP Pix seront conservés dans des conditions
    raisonnables de sécurité et considérés comme les preuves des communications intervenues entre les parties. Elles
    font foi jusqu’à preuve du contraire.
  </p>
  <p>
    L’archivage des conditions d’utilisation de Pix Certif et des services est effectué sur un support fiable et durable
    pouvant être produit à titre de preuve.
  </p>

  <h2 class='pix-title-s'>20. Nullité</h2>

  <p>
    Si une ou plusieurs stipulations des présentes conditions d’utilisation sont tenues pour non valides ou déclarées
    comme telles en application d’une loi, d’un règlement ou à la suite d’une décision passée en force de chose jugée
    d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
  </p>

  <h2 class='pix-title-s'>21. Loi applicable</h2>

  <p>
    Les présentes conditions d’utilisation sont régies par la loi française. Il en est ainsi pour les règles de fond et
    les règles de forme et ce, nonobstant les lieux d’exécution des obligations substantielles ou accessoires.
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
