import { template as template_cffe992db1e64b03a16d2670ec5007aa } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
export default template_cffe992db1e64b03a16d2670ec5007aa(`
  <nav class='navbar session-details__controls-navbar-tabs'>
    <LinkTo @route='authenticated.sessions.details.parameters' class='navbar-item'>
      {{t 'pages.sessions.detail.tabs.details'}}
    </LinkTo>
    <LinkTo @route='authenticated.sessions.details.certification-candidates' class='navbar-item'>
      {{t 'common.sessions.candidates'}}
      {{@certificationCandidatesCount}}
    </LinkTo>
  </nav>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
