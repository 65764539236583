import { template as template_bfcc9383ec9e43289b2d9ce37a13ac41 } from "@ember/template-compiler";
export default template_bfcc9383ec9e43289b2d9ce37a13ac41(`
  <li class='row'>
    <span class='row__label'>
      {{@label}}
    </span>
    <span class='row__value'>
      {{@value}}
    </span>
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
